

ymaps.ready(init);
function init(){
    // Создание карты.
    var myMap = new ymaps.Map("map", {
        // Координаты центра карты.
        // Порядок по умолчанию: «широта, долгота».
        // Чтобы не определять координаты центра карты вручную,
        // воспользуйтесь инструментом Определение координат.
        center: [44.039608, 43.050511],
        // Уровень масштабирования. Допустимые значения:
        // от 0 (весь мир) до 19.
        zoom: 16
        
    });
    myMap.behaviors.disable('scrollZoom');
    myMap.geoObjects
        .add(new ymaps.Placemark([44.039608, 43.050511], {
        }, {
            preset: 'islands#icon',
            iconColor: '#0095b6'
        }));
        
}
$(document).ready(function () {
    $(document).find('.preview-slider__list').slick({
        dots: true,
        arrows: false,
    });
    $(document).find('.header-mobile__burger').on('click', function(e){
        e.preventDefault();
        $('.burger-menu').toggleClass('burger-menu_open');
    });
    $(document).find('.burger-menu__close').on('click', function(e){
        e.preventDefault();
        $('.burger-menu').toggleClass('burger-menu_open');
    });
    $(document).find('.burger-menu__list a').on('click', function(e){
        $('.burger-menu').toggleClass('burger-menu_open');
    });

    // Калькулятор

    // $(document).find('.calc__btn').on('click', function(e) {
    //     var $this = $(this).attr('data-calc');
    //         if ( $this == 'calc1' ) {
    //             $(document).find('[data-act1]').attr('data-act1', 5500);
    //             $(document).find('[data-act2]').attr('data-act1', 6500);
    //             $(document).find('[data-act3]').attr('data-act1', 7500);
    //             $(document).find('[data-act4]').attr('data-act1', 7500);
    //             $(document).find('[data-act5]').attr('data-act1', 7500);
    //         } else if ( $this == 'calc2' ) {
    //             $(document).find('[data-act1]').attr('data-act1', 6500);
    //             $(document).find('[data-act2]').attr('data-act1', 7500);
    //             $(document).find('[data-act3]').attr('data-act1', 8500);
    //             $(document).find('[data-act4]').attr('data-act1', 8500);
    //             $(document).find('[data-act5]').attr('data-act1', 8500);
    //         }  else if ( $this == 'calc3' ) {
    //             $(document).find('[data-act1]').attr('data-act1', 5500);
    //             $(document).find('[data-act2]').attr('data-act1', 6500);
    //             $(document).find('[data-act3]').attr('data-act1', 7500);
    //             $(document).find('[data-act4]').attr('data-act1', 7500);
    //             $(document).find('[data-act5]').attr('data-act1', 7500);
    //         } else if ( $this == 'calc4' ) {
    //             $(document).find('[data-act1]').attr('data-act1', 5500);
    //             $(document).find('[data-act2]').attr('data-act1', 6500);
    //             $(document).find('[data-act3]').attr('data-act1', 7500);
    //             $(document).find('[data-act4]').attr('data-act1', 7500);
    //             $(document).find('[data-act5]').attr('data-act1', 7500);
    //         } else {

    //         }

    //     console.log($this);

    // });

    
});